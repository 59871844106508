import { lazy } from 'react';
/**
 Maps the Monarch UI Component customId to the React component path in our repo
 * */
const listOfMonarchComponents = {
  frdcSectionOffersEditable: lazy( () => import( '@monarch/Section | Offers - Editable' ) ),
  infrSectionEmailCaptureEditable: lazy( () => import( '@monarch/Section | Email Capture - Editable' ) ),
  frdcNavBannerMobileEditable: lazy( () => import( '@monarch/Nav | Banner | Mobile - Editable' ) ),
  frdcHeroRedesignEditable: lazy( () => import( '@monarch/Hero | Redesign - Editable' ) ),
  infrSectionEmpty: lazy( () => import( '@monarch/Section | Empty' ) ),
  frdcBannerDaysCountdownEditable: lazy( () => import( '@monarch/Banner | Days Countdown - Editable' ) ),
  frdcSectionGdtEditable: lazy( () => import( '@monarch/Section | GDT - Editable' ) ),
  frdcSectionBentoFiberEducationEditable: lazy( () => import( '@monarch/Section | Bento | Fiber Education - Editable' ) ),
  frdcSectionTestimonialsEditable: lazy( () => import( '@monarch/Section | Testimonials - Editable' ) ),
  frdcBannerServiceCheckSingleLine: lazy( () => import( '@monarch/Banner | Service Check Single Line' ) ),
  infrHeroCarouselRedesignEditable: lazy( () => import( '@monarch/Hero | Carousel | Redesign - Editable' ) ),
  frdcHeroPricePointTieredEditable: lazy( () => import( '@monarch/Hero | Price Point | Tiered - Editable' ) ),
  frdcBannerCountdownEditable: lazy( () => import( '@monarch/Banner - Countdown - Editable' ) ),
  infrSectionProductCardsBricksEditable: lazy( () => import( '@monarch/Section | Product Cards (Bricks) - Editable' ) ),
  infrHeroEditable: lazy( () => import( '@monarch/Hero - Editable' ) ),
  frdcSectionPlanFeatures: lazy( () => import( '@monarch/Section | Plan Features' ) ),
  infrModalGdt: lazy( () => import( '@monarch/Home | Modal | GDT' ) ),
  infrModalReturnVisitorRefreshImage: lazy( () => import( '@monarch/Modal | Return Visitor Refresh Image' ) ),
  frdcSectionFaQsFrdcRedesign: lazy( () => import( '@monarch/Section | FAQs | FRDC redesign' ) ),
  infrSectionGdtQuiz: lazy( () => import( '@monarch/Section | GDT | QUIZ' ) ),
  infrSectionStickyCta: lazy( () => import( '@monarch/Section | Sticky CTA' ) ),
  frdcSectionWhyFrontierEditable: lazy( () => import( '@monarch/Section | Why Frontier - Editable' ) ),
  frdcSectionVasEditable: lazy( () => import( '@monarch/Section | VAS - Editable' ) ),
  frdcShopHeroPricePointTiered5G: lazy( () => import( '@monarch/Shop - Hero | Price Point - Tiered 5G' ) ),
  infrSectionStickyCtaGdt: lazy( () => import( '@monarch/Section | Sticky CTA | GDT' ) ),
  infrSectionGdtCta: lazy( () => import( '@monarch/Section | GDT | CTA' ) ),
  frdcSectionComparisonGrid: lazy( () => import( '@monarch/Section | Comparison Grid' ) ),
  infrModalReturnVisitorRefreshDualCta: lazy( () => import( '@monarch/Modal | Return Visitor Refresh Dual CTA' ) ),
  frdcBannerHeadingEditable: lazy( () => import( '@monarch/Banner | Heading - Editable' ) ),
  frdcSectionProductCardsExpansionEditable: lazy( () => import( '@monarch/Section | Product Cards | Expansion - Editable' ) ),
  frdcNavBannerDesktopEditable: lazy( () => import( '@monarch/Nav | Banner | Desktop - Editable' ) )
};

export default listOfMonarchComponents;
